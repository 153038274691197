@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap"); */

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  display: flex;
  min-width: 100vw;
  min-height: 100dvh;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  /* font-family: "Poppins", sans-serif; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  font-family: "Poppins", sans-serif;
  /* scrollbar-color: #b2b2b2 #ffffff; */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  /* width: 7px;
  height: 7px; */
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 4px;
  border: 1px none #ffffff;
}

.noscrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  overflow-y: hidden !important;
}

.pointer {
  text-decoration: none;
  /* color: black; */
}

.pointer:hover {
  cursor: pointer;
  filter: brightness(1.05);
  /* color: #c1c1c1; */
  /* outline: 1.5px dashed silver; */
}

.pointer_touch {
  filter: drop-shadow(1px 1px 2px #07a69c);
}

.pointer_touch2 {
  filter: drop-shadow(1px 2px 3px #ed5a29);
}

.capitalize {
  text-transform: capitalize !important;
}

.unset {
  text-transform: unset !important;
}

.underline {
  text-decoration: underline;
}

.placeholder {
  color: #b9b9b9 !important;
}

.selected {
  font-weight: 800 !important;
}

.tx800 {
  font-weight: 800 !important;
}

.tx700 {
  font-weight: 700 !important;
}

.tx600 {
  font-weight: 600 !important;
}

.tx500 {
  font-weight: 500 !important;
}

.tx400 {
  font-weight: 400 !important;
}

.tx300 {
  font-weight: 300 !important;
}

.tx60 {
  /* font-size: 0.6rem !important; */
  font-size: 9.75px !important;
}

.tx70 {
  /* font-size: 0.6875rem !important; */
  font-size: 10.5px !important;
}

.tx75 {
  /* font-size: 0.75rem !important; */
  font-size: 12px !important;
}

.tx80 {
  /* font-size: 0.8125rem !important; */
  font-size: 12.75px !important;
}

.tx85 {
  /* font-size: 0.85rem !important; */
  font-size: 13.75px !important;
}

.tx90 {
  /* font-size: 0.9rem !important; */
  font-size: 14.25px !important;
}

.tx95 {
  /* font-size: 0.95rem !important; */
  font-size: 14.5px !important;
}

.tx100 {
  /* font-size: 1rem !important; */
  font-size: 16px !important;
}

.tx117 {
  /* font-size: 1.1rem !important; */
  font-size: 17px !important;
}

.tx110 {
  /* font-size: 1.1rem !important; */
  font-size: 17.25px !important;
}

.tx115 {
  font-size: 18px !important;
}

.tx125 {
  /* font-size: 1.25rem !important; */
  font-size: 20px !important;
}

.fs15 {
  /* font-size: 1rem !important; */
  font-size: 15px !important;
}

.tx150 {
  font-size: 24px !important;
}

.tx175 {
  font-size: 28px !important;
}

.c1 {
  color: #ed5a29 !important;
}

.c2 {
  color: #007358 !important;
}

.c2a {
  color: #008577 !important;
}

.c2b {
  color: #07a69c !important;
}

.c3 {
  color: #6e7191 !important;
}

.c4 {
  color: #4e4b66 !important;
}

.c4a {
  color: #262338 !important;
}

.c5 {
  color: #fff !important;
}

.red {
  border: 1px solid red;
}

.bg_gray {
  background: #eff0f6;
}

.hover_green:hover {
  /* color: #07A69C; */
  background-color: #07a69c !important;
  color: "#F7F7FC";
  filter: brightness(1.05) !important;
}

.hover_gray:hover {
  background-color: #07a69b35 !important;
  filter: brightness(1.05) !important;
}

.hover_orange {
  background-color: #ed5a29 !important;
  color: "#F7F7FC";
  filter: brightness(1.05) !important;
}

.hover_orange_disabled {
  background-color: #ed5a29 !important;
  color: "#F7F7FC";
  filter: brightness(0.5) !important;
}

.hover_white {
  background-color: #fff !important;
  color: #07a69c !important;
  filter: brightness(1.05) !important;
}

.hover_white:hover {
  background-color: #fff !important;
  color: #07a69c !important;
  filter: brightness(0.95) !important;
}

.poppins {
  font-family: "Poppins", sans-serif !important;
}

.btn_orange {
  padding: 0.4rem !important;
  border-width: 2px !important;
  border-color: #ed5a29 !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  letter-spacing: 0.75px;
}

.ls25 {
  letter-spacing: 0.25px;
}

.ls75 {
  letter-spacing: 0.75px;
}

.ls100 {
  letter-spacing: 1px;
}

.lh150 {
  line-height: 1.5rem;
}

.fade {
  opacity: 0;
  transform: translateY(100%);
  transition: z-index 0.3s ease-in-out, opacity 0.3s ease-in-out;
  bottom: 0;
  left: 0;
  position: fixed;
}

.fade.visible {
  opacity: 1;
  z-index: 100;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.disabled {
  background-color: #e2e3ed !important;
  color: #4e4b66 !important;
}

.sliding {
  animation: slideRight 1.25s ease-in-out forwards infinite;
}

.slide_fade {
  animation: slideFadeRight 1.25s ease-in-out forwards infinite;
}

@keyframes slideRight {
  0% {
    transform: translateX(-12px);
    opacity: 0;
  }
  /* 70% {
    opacity: 1;
  } */
  95% {
    transform: translateX(5px);
    /* opacity: 1; */
  }
  100% {
    transform: translateX(4px);
  }
}

@keyframes slideFadeRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wave::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, transparent, transparent, #ff7d50, transparent, transparent);
  background-size: 500% 200%;
  animation: skeleton-wave 25s infinite ease-in-out;
  border-radius: 12px;
}

/* Skeleton wave animation */
@keyframes skeleton-wave {
  0% {
    background-position: -500% 0;
  }
  100% {
    background-position: 500% 0;
  }
}
